// fonts
$np-black: 'NeuePlak-Black', sans-serif;
$np-bold: 'NeuePlak-Bold', sans-serif;
$np-nblack: 'NeuePlak-NarrowBlack', sans-serif;
$np-regular: 'NeuePlak-Regular', sans-serif;
$np-semibold: 'NeuePlak-SemiBold', sans-serif;
$np-cblack: 'NeuePlak-CompBlack', sans-serif;
$np-cdblack: 'NeuePlak-CondBlack', sans-serif;


// colors
$red: #dc0019;
$black: #000000;
$white: #FFFFFF;
$silver: #bbbbbb;
$dark-grey: #494949;
$mid-grey: #9b9b9b;
$light-grey: #e3e3e5;

// transition
$tr: all .3s ease-out;
