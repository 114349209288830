.main {
    flex-grow: 1;
    display: flex;
    &>* {
        width: 100%;
        display: flex; }

    &.active-popup {
        filter: blur(2px); } }


.hero {
    position: relative;
    margin-bottom: 24px;
    // &:before, &:after
    //     content: ''
    //     position: absolute
    //     width: 100%
    //     height: 100%
    //     top: 0
    //     bottom: 0
    //     left: 0
    //     right: 0
    //     margin: auto
    // &:before
    //     background: rgba(0, 0, 0, 0.5)
    // &:after
    //     background: rgba(220, 0, 25, 0.1)
    // &-bg
    //     position: absolute
    //     width: 100%
    //     height: 100%
    //     top: 0
    //     left: 0
    //     right: 0
    //     bottom: 0
    //     margin: auto
    //     background-image: url(../images/hero-bg.svg)
    //     background-repeat: no-repeat
    //     background-size: cover
    //     background-position: center
    // &-title
    //     margin-bottom: 12px
    // &-txt
    //     font-size: 14px
    //     line-height: 21px
    //     color: #E0E0E0
    //     text-align: center
    &-img {
        height: 100%;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            max-width: 100%;
            position: absolute;
            object-fit:  contain;
            object-position: center; } }
    &-inner {
        position: relative;
        z-index: 1; }
    &-btns {
        margin-bottom: 19px; }
    &-links {
        text-align: center;
        margin-bottom: 40px;
        &__txt {
            font-size: 14px;
            line-height: 21px;
            color: #797575; } }
    &-link {
        font-size: 14px;
        line-height: 21px;
        color: $white;
        margin-left: 6px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: $white;
            left: 0;
            right: 0;
            bottom: 0;
            transition: $tr; }
        &:hover, &:focus, &:active {
            color: $red;
            &:after {
                background: $red; } } } }

.about {
    padding-top: 26px;
    margin-bottom: 135px;
    &-title {
        margin-bottom: 12px; }
    &-txt {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.5px;
        color: #E0E0E0;
        margin-bottom: 32px; }
    &-items {
        position: relative;
        counter-reset: item; }
    &-item {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        counter-increment: item;
        &:last-child {
            margin-bottom: 0; }
        &__title {
            margin-bottom: 6px;
            &:before {
                content: counter(item) ". "; } }
        &__descr {
            font-size: 14px;
            line-height: 21px;
            color: #E0E0E0; } }
    &-info {
        width: calc(100% - 96px); }
    &-img {
        width: 80px;
        height: 80px;
        margin-right: 16px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #575757;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        &__icon {
            width: 40px;
            height: 40px;
            padding: 8px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.2);
            display: flex;
            align-items: center;
            justify-content: center; } } }

.video {
    flex-direction: column;
    &-title {
        text-align: center;
        margin-bottom: 40px; }
    &-cnt {
        video {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        position: fixed;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        padding: 42px 0 32px;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 40%); } }

.game {
    align-items: center;
    justify-content: center; }

.start {
    padding-top: 16px;
    margin-bottom: 135px;
    &-item {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0; }
        &__link {
            display: flex;
            flex-direction: column;
            background: rgba(255, 255, 255, 0.1);
            border: 1px solid #575757;
            box-sizing: border-box;
            border-radius: 4px;
            padding: 16px; }
        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 15px; }
        &__title {
            display: block;
            font-family: $np-semibold;
            font-size: 21px;
            line-height: 32px;
            text-transform: uppercase;
            color: $white; }
        &__img {
            img {
                display: inline-block;
                vertical-align: bottom; } }
        &__list {
            display: flex;
            flex-direction: column;
            &-item {
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0; } }
            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                width: 24px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.2);
                margin-right: 12px; }
            &-txt {
                font-family: $np-nblack;
                font-size: 16px;
                line-height: 24px;
                text-transform: uppercase;
                color: $white; } } } }

.rotate {
    padding-top: 220px;
    &-inner {
        max-width: 132px;
        width: 100%;
        margin: auto;
        text-align: center; }
    &-img {
        margin-bottom: 20px; }
    &-txt {
        font-family: $np-semibold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.001em;
        color: $white; } }

.completed {
    padding-top: 25px;
    margin-bottom: 135px;

    &--loader {
        margin-bottom: 0; }
    &-loader {
        margin-top: auto;
        margin-bottom: 32px; }
    &-title {
        margin-bottom: 144px; }
    &-img {
        width: 140px;
        margin: 0 auto 19px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            width: 87px;
            height: 87px;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.2);
            top: -7px;
            right: 15px;
            z-index: -1; }
        img {
            margin-left: 10px; }
        &--check {
            width: 87px;
            height: 87px;
            &:before {
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto; }
            img {
                margin-left: 17px;
                margin-top: 5px; } } }
    &-subtitle {
        text-align: center;
        color: $white;
        margin-bottom: 8px; }
    &-descr {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.2px;
        color: $white; }
    &-info-title {
        font-family: $np-cdblack;
        font-size: 32px;
        line-height: 42px;
        text-transform: uppercase;
        color: $white;
        text-align: center;
        margin-bottom: 8px; }
    &--nimg {
        .completed-title {
            margin-bottom: 184px; } } }

.index {
    flex-direction: column; }

.form {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    &-section {
        padding-top: 24px; }
    &-profile {
        padding-top: 75px; }
    &-title {
        margin-bottom: 12px;
        &--mb {
            margin-bottom: 33px; } }
    &-sub-title {
        font-family: $np-black;
        font-size: 16px;
        line-height: 21px;
        margin-top: -10px;
        margin-bottom: 31px;
        color: $white; }
    &-descr {
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 31px;
        color: #E0E0E0; }
    &-group {
        display: flex;
        align-items: center;
        width: 100%;
        &--bd {
            position: relative;
            &:after {
                content: '';
                position: absolute;
                width: calc(100% + 32px);
                left: -16px;
                right: -16px;
                height: 1px;
                background: #494949;
                bottom: 0; } }
        &--skip {
            margin-top: 24px; }
        &--pass {
            .form-label {
                width: 117px; }
            .form-control {
                width: calc(100% - 117px); } } }
    &-label {
        display: block;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.25px;
        color: $white;
        width: 95px;
        text-transform: capitalize; }
    &-control {
        width: calc(100% - 95px);
        height: 48px;
        background: rgba(255, 255, 255, 0);
        border: 0;
        font-family: $np-semibold;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.001em;
        color: $white;
        padding: 0;
        &--date {
            background-image: url(../images/date.svg);
            background-repeat: no-repeat;
            background-size: auto;
            background-position: 93% 50%;
            &::placeholder {
                color: $white; } } }
    &-checkbox {
        display: flex;
        align-items: center;
        padding: 25px 0 0;
        input {
            display: none;
            &:checked ~ .form-checkmark:after {
                transform: scale(1);
                opacity: 1; } } }
    &-checkmark {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 2px;
        margin-right: 16px;
        position: relative;
        &:after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background: $red;
            border-radius: 1px;
            transform: scale(0);
            opacity: 0;
            transition: $tr; } }
    &-checktext {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.25px;
        color: $white;
        width: calc(100% - 40px);
        &--alt {
            white-space: nowrap; }
        a {
            color: $white;
            border-bottom: 1px solid $white;
            &:hover {
                color: $red;
                border-bottom: 1px solid $red; } } }
    &-links {
        width: 100%;
        padding-top: 24px;
        &--center {
            text-align: center;
            margin-bottom: 19px;
            padding-top: 0; } }
    &-link {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.5px;
        color: $red;
        &--center {
            font-size: 14px;
            line-height: 21px;
            color: $white;
            display: inline-block;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                height: 1px;
                width: 100%;
                background: $white;
                bottom: 1px;
                left: 0;
                right: 0;
                margin: auto;
                transition: $tr; }
            &:hover, &:focus, &:active {
                color: $red;
                &:after {
                    background: $red; } } } }
    &-btns {
        width: 100%;
        padding: 24px 0; }
    &-inner {
        flex-grow: 1;
        width: 100%;
        margin-bottom: 32px; } }

.custom-select-container {
    width: calc(100% - 95px); }
.custom-select-opener {
    height: 48px;
    background: rgba(255, 255, 255, 0);
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    font-family: $np-semibold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.001em;
    color: $white;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0;
        bottom: 0;
        right: 7%;
        margin: auto;
        background-image: url(../images/arrow.svg);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        transition: $tr; }
    &[aria-expanded="true"] {
        &:after {
            transform: rotate(180deg); } } }

.custom-select-panel {
    background: #000;
    border-radius: 8px;
    margin-top: 10px; }
.custom-select-option {
    padding: 15px 16px;
    font-family: $np-semibold;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.001em;
    color: $white;
    &.is-selected {
        &:before {
            content: none; } }
    &.is-selected.has-focus {
        background: $red; } }
.custom-select-container.customSelect:focus, .custom-select-opener:focus {
    outline: 0; }

.qs-datepicker-container {
    margin-top: 10px;
    left: 0 !important;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #575757; }
.qs-controls {
    padding: 8px;
    background: #575757; }
.qs-squares {
    background: #000; }
.qs-month-year {
    color: $white; }
.qs-day {
    color: $red; }
.qs-num {
    color: $white; }
.qs-current {
    font-family: $np-semibold;
    text-decoration: none; }
.qs-arrow.qs-right:after {
    border-left-color: $white; }
.qs-arrow.qs-left:after {
    border-right-color: $white; }

.single {
    padding-top: 84px;
    padding-bottom: 50px;
    margin-bottom: 135px;
    &-title {
        margin-bottom: 12px; }
    &-descr {
        font-size: 14px;
        line-height: 21px;
        color: #E0E0E0;
        margin-bottom: 16px; }
    &-txt {
        font-size: 16px;
        line-height: 21px;
        color: #F4F4F4; } }

.training {
    padding-top: 76px;
    padding-bottom: 41px;
    &-back {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        font-family: $np-semibold;
        font-size: 14px;
        line-height: 21px;
        text-transform: uppercase;
        color: $white;
        i {
            margin-right: 9px;
            margin-bottom: -2px; } }
    &-descr {
        font-size: 16px;
        line-height: 24px;
        color: #E0E0E0;
        margin-bottom: 19px; }
    &-title {
        margin-bottom: 24px;
        &--sm {
            margin-bottom: 12px;
            font-family: $np-nblack;
            font-size: 21px;
            line-height: 32px; } }

    &-section {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0; } }
    &-item {
        &__link {
            display: flex;
            flex-direction: column;
            background: rgba(255, 255, 255, 0.1);
            padding: 16px 16px 12px;
            width: 172px;
            min-height: 144px; }
        &__img {
            width: 64px;
            margin-bottom: 14px;
            img {
                width: 100%; } }
        &__title {
            font-family: $np-nblack;
            font-size: 21px;
            line-height: 32px;
            text-transform: uppercase;
            color: $white; }
        &__descr {
            font-size: 12px;
            line-height: 16px;
            color: #F4F4F4; } }
    &-link {
        &__inner {
            position: relative;
            display: flex;
            flex-direction: column;
            width: 172px; }
        &__img {
            padding-top: 81.5%;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%); }
            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 100%;
                height: 100%; } }
        &__info {
            position: absolute;
            bottom: 15px;
            left: 16px;
            display: flex;
            flex-direction: column;
            object-fit: cover;
            z-index: 1; }
        &__title {
            font-family: $np-nblack;
            font-size: 21px;
            line-height: 32px;
            color: $white;
            margin-bottom: 1px; }
        &__descr {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.25px;
            color: #F4F4F4; } }
    &-article {
        position: relative;
        &__link {
            position: relative;
            display: flex;
            flex-direction: column; }
        &__img {
            padding-top: 50%;
            position: relative;
            &:after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.8) 100%); }
            img {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 100%;
                height: 100%; } }
        &__logo {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            width: 164px;
            height: 22px; }
        &__txt {
            position: absolute;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: 0.4px;
            color: $white;
            bottom: 21px;
            left: 16px;
            i {
                margin-left: 8px; } } } }
.swiper-slide {
    width: auto; }
.swiper-container {
    overflow: visible; }

.descr {
    padding-top: 24px;
    padding-bottom: 125px;
    &-name {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
        &__img {
            width: 40px;
            height: 40px;
            margin-right: 16px;
            img {
                max-width: 100%; } }
        &__txt {
            width: calc(100% - 56px); } }
    &-title {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        &__img {
            width: 32px;
            height: 32px;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.2); }
        &__txt {
            width: calc(100% - 44px);
            font-family: $np-semibold; } }
    &-txt {
        font-size: 14px;
        line-height: 21px;
        color: #E0E0E0;
        margin-bottom: 24px; }
    &-img {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            max-width: 100%; } } }

.changed {
    padding-top: 0;
    .wrapper {
        justify-content: space-around; }
    .completed-img {
        width: 88px;
        img {
            margin: 0; }
        &:before {
            content: none; } } }

.check-mail {
    padding-top: 0;
    margin-bottom: 160px;
    .wrapper {
        justify-content: space-around; }
    .completed-img {
        width: 88px;
        height: 88px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            margin: 0; }
        &:before {
            top: 0;
            left: 0;
            right: auto; } }
    .form-sub-title {
        text-align: center;
        max-width: 320px;
        margin: 18px auto; }
    &-footer {
        max-width: 850px;
        min-width: 320px;
        margin: 0 auto;
        padding: 0 30px;
        color: $white;
        line-height: 21px;
        text-align: center;
        a {
            color: $white;
            text-decoration: underline; } } }


.stack-up {
    .wrapper {
        justify-content: space-around;
        padding-bottom: 84px; } }


.player {
    padding: 135px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    &-video {
        position: relative;
        padding-top: 57%;
        width: 100%;
        iframe {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%; } } }

.desktop {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    .wrapper {
        align-items: center;
        justify-content: center; } }

.footer, .header, .section, .video, .game, .index {
    display: none; }
.mobile {
    .desktop {
        display: none; }
    .footer, .header, .section {
        display: block; }
    .video, .game, .index {
        display: flex; } }

.loader {
    height: 30px;
    width: 100%;
    border-radius: 20px;
    position: relative;
    font-weight: 300;
    font-size: 18px;
    &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.2) 50%,rgba(255,255,255,0) 100%);
        position: absolute;
        top: 0;
        left: 0; }
    &.active {
        .loader {
            &-glow, &-underglow {
                width: 100%; } } }
    &--sm {
        max-width: 220px;
        height: 18px;
        margin: 0 auto;
        font-size: 11px;
        .loader {
            &-track {
                line-height: 18px; } } }
    &-track {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        color: #fff;
        text-align: center;
        line-height: 30px;
        overflow: hidden;
        position: relative;
        opacity: 0.99; }
    &-glow {
        width: 0%;
        height: 100%;
        background: linear-gradient(to bottom, rgba(255, 0, 0, 1)0%,rgba(220, 0, 25, 1) 100%);
        box-shadow: 0px 0px 14px 1px rgba(220, 0, 25, 1);
        position: absolute;
        top: 0;
        left: 0;
        animation: flicker 5s infinite;
        overflow: hidden;
        transition: width 4s;
        z-index: 2; }
    &-underglow {
        width: 0%;
        height: 0%;
        border-radius: 20px;
        box-shadow: 0px 0px 60px 10px rgba(220, 0, 25, 1);
        position: absolute;
        bottom: 0;
        animation: flicker 5s infinite;
        transition: width 4s; }
    &-front {
        color: #000 {}
        font-family: $np-nblack;
        position: absolute;
        top: -2px;
        left: 0;
        right: 0;
        margin: auto; }
    &-back {
        font-family: $np-nblack;
        color: #222;
        position: relative;
        top: -2px;
        left: 0;
        right: 0;
        margin: auto; } }

.game {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    backdrop-filter: blur(8px);
    background: linear-gradient(154.49deg, rgba(121, 117, 131, 0.2) 5.35%, rgba(54, 53, 103, 0.2) 83.85%), linear-gradient(180deg, rgba(35, 37, 38, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        background-image: url(../images/game-bg.png);
        background-repeat: no-repeat;
        background-position: center;
        opacity: 0.5; }
    &--2 {
        .game-field {
            @include rh(820px) {
                max-height: none; } } }
    &-field {
        max-width: 414px;
        max-height: 727px;
        width: 100%;
        height: 100%;
        position: relative;
        @include r(767px) {
            border: 0; }
        @include rh(800px) {
            border: 0; } }
    &-item {
        position: absolute;
        cursor: pointer;
        img {
            max-width: 100%; }
        &-1 {
            left: 64px;
            top: 0;
            bottom: 0;
            transform: translateY(-69px);
            margin: auto;
            width: 40px;
            height: 40px; }
        &-2 {
            right: 64px;
            top: 0;
            bottom: 0;
            transform: translateY(-69px);
            margin: auto;
            width: 40px;
            height: 40px; }
        &-3 {
            left: 80px;
            bottom: 64px;
            width: 40px;
            height: 40px; }
        &-4 {
            right: 80px;
            bottom: 64px;
            width: 40px;
            height: 40px; }
        &-5 {
            width: 64px;
            height: 64px;
            top: 64px;
            left: 0;
            right: 0;
            margin: auto; }
        &-6 {
            width: 64px;
            height: 64px;
            left: 64px;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: translateY(-80px); }

        &-7 {
            width: 64px;
            height: 64px;
            display: flex;
            align-items: center;
            justify-content: center;
            right: 52px;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: translateY(-80px); }

        &-8 {
            width: 40px;
            height: 40px;
            bottom: 64px;
            left: 0;
            right: 0;
            margin: auto; } } }

@keyframes flicker {
    10% {
        opacity: 0.9; }
    30% {
        opacity: 0.86; }
    60% {
        opacity: 0.8; }
    80% {
        opacity: 0.75; } }

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(61, 61, 60, 0.7); }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(61, 61, 60, 0); }
    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(61, 61, 60, 0); } }

@keyframes pulseLink {
    0% {
        transform: scale(1.1); }
    50% {
        transform: scale(1); }
    100% {
        transform: scale(1.1); } }
