.footer {
    padding-bottom: 22px;
    padding-top: 42px;
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 40%);
    z-index: 5;
    &--btns {
        .footer-btns {
            display: flex;
            align-items: center; }
        .footer-btn {
            justify-content: center;
            padding: 2px 0 10px;
            font-size: 28px;
            line-height: 28px;
            i {
                width: 12px;
                margin-top: 8px;
                margin-left: 6px; }
            &:first-child {
                width: 52%;
                max-width: 162px;
                &:hover, &:focus, &:active {
                    i {
                        @include svg-pos-image-share-red; } } }
            &:last-child {
                width: 46%;
                max-width: 150px;
                &:hover, &:focus, &:active {
                    i {
                        @include svg-pos-image-goto-red; } } } } }

    &-btns {
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0; } }
    &-links {
        text-align: center;
        &__txt {
            font-size: 14px;
            line-height: 21px;
            color: #797575; } }
    &-link {
        font-size: 14px;
        line-height: 21px;
        color: $white;
        margin-left: 8px;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background: $white;
            left: 0;
            right: 0;
            bottom: 0;
            transition: $tr; }
        &:hover, &:focus, &:active {
            color: $red;
            &:after {
                background: $red; } } }
    &--wlogo {
        padding-top: 0;
        padding-bottom: 28px;
        background: transparent; }
    &-wlogo {
        display: flex;
        align-items: center;
        justify-content: center;
        @include r(359px) {
            flex-direction: column;
            align-items: flex-start; }
        &__txt {
            font-family: $np-semibold;
            font-size: 14px;
            line-height: 21px;
            color: #797575;
            margin-right: 30px;
            @include r(359px) {
                margin-right: 0;
                margin-bottom: 10px; } }
        .header-logo {
            &__line {
                margin: 0 11px;
                width: 1px;
                height: 24px;
                display: block;
                background: #797575; }
            img {
                &:first-child {
                    width: 40px; }
                &:last-child {
                    width: 168px; } } } } }

.fixed-btn {
    .footer {
        padding-top: 42px;
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 40%); } }
