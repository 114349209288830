.drills {
    padding-top: 26px;
    margin-bottom: 135px;

    &__title {
        font-size: 48px;
        line-height: 48px;
        margin-bottom: 12px; }

    &__items {
        counter-reset: item;
        position: relative;
        margin-top: 16px; }

    &__item {
        counter-increment: item;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        margin: 16px 0;
        background: rgba(255, 255, 255, 0.1);
        h3 {
            &:before {
                content: counter(item) ". "; } } }

    &__info {}

    &__img {} }
