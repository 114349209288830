.header {
    padding-top: 32px;
    padding-bottom: 14px;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    z-index: 6;
    &--nav {
        filter: drop-shadow(4px 4px 20px rgba(0, 0, 0, 0.05));
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
        padding: 14px 0;
        .nav-item {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0; }
            &.active {
                .nav-link {
                    .nav-icon {
                        &--default {
                            opacity: 0; }
                        &--hover {
                            opacity: 1; } } } } }
        .nav-link {
            font-family: $np-nblack;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
            color: $white;
            display: flex;
            align-items: center;
            &:hover, &:focus, &:active {
                color: $red;
                .nav-icon {
                    &--default {
                        opacity: 0; }
                    &--hover {
                        opacity: 1; } } } }
        .nav-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 19px;
            height: 32px;
            margin-left: 13px;
            i {
                margin-bottom: 6px; }
            &--hover {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                opacity: 0;
                transition: $tr; }
            &--default {
                transition: $tr; } } }
    &-user {
        display: flex;
        align-items: center;
        width: calc(100% - 30px);
        margin-right: 6px;
        &__img {
            max-width: 100%;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            display: none;
            &-block {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background: $red;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 16px; }
            &-ex {
                width: auto;
                height: auto;
                border-radius: 0;
                max-width: none; } }
        &__name {
            font-family: $np-nblack;
            font-size: 21px;
            line-height: 32px;
            text-transform: uppercase;
            color: $white; } }
    &-logo {
        width: 100%;
        display: flex;
        align-items: center;

        &__line {
            width: 1px;
            height: 24px;
            display: block;
            margin: 0 12px;
            background: #797575; }
        img {
            &:first-child {
                width: 179px; }
            &:last-child {
                width: 43px; } } }
    &-nav {
        display: flex;
        align-items: center;
        justify-content: space-between; }
    &-navbar {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 40px 16px 24px;
        opacity: 0;
        visibility: hidden;
        transform: translateX(20%);
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        transition: $tr, height 0s;
        &.show {
            opacity: 1;
            visibility: visible;
            transform: translateX(0); }
        &__inner {
            min-height: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column; } }
    &-burger {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 5;
        &-inner {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column; }
        &-line {
            width: 16px;
            height: 2px;
            background: $white;
            border-radius: 2px;
            margin-bottom: 6px;
            transition: $tr;
            &:last-child {
                margin-bottom: 0; } }
        &.active {
            .header-burger-line {
                &:first-child {
                    transform: rotate(45deg) translateX(5.5px) translateY(6px); }
                &:last-child {
                    transform: rotate(-45deg); } } } }
    &-title {
        font-family: $np-nblack;
        font-size: 21px;
        line-height: 32px;
        text-transform: uppercase;
        color: $white; }
    &-bottom {
        &__txt {
            font-family: $np-semibold;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.001em;
            color: #F4F4F4;
            margin-bottom: 14px; }
        &__policy {
            margin-top: 14px;
            a {
                text-decoration: underline; }
            a, span {
                margin-right: 8px;
                font-family: $np-regular;
                font-size: 14px;
                line-height: 21px;
                color: $mid-grey; } } }

    &-list {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0; } } }
