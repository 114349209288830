.cognition {
    padding-top: 76px;
    margin-bottom: 135px;

    &-wrapp {
        &.active {
            .cognition-logo {
                transform: translateY(0) scale(1);
                opacity: 1; }
            .cognition-tabs {
                opacity: 1; }
            .cognition-cnt {
                opacity: 1; } } }

    &-pointer {
        position: absolute;
        top: 0;
        left: 0;

        [class^="svg-image-"] {
            width: 18px; }

        & > div {
            transition: transform 0.3s ease; }
        &-v5 {
            transform: translate(5px, 75px); }
        &-v1 {
            transform: translate(92px, 8px); }
        &-v2 {
            transform: translate(178px, 75px); }
        &-v3 {
            transform: translate(140px, 176px); }
        &-v4 {
            transform: translate(38px, 176px); } }
    &-tabs {
        position: relative;
        width: 182px;
        height: 174px;
        margin: 0 auto 35px;
        opacity: 0;
        transition: all 2s ease-out;
        transition-delay: 1.5s; }

    &-tab {
        position: absolute;
        width: 32px;
        height: 32px;
        .tab-link {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            &:before {
                content: '';
                z-index: 0;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background: #3D3D3C;
                transform: scale(1);
                pointer-events: none;
                animation: pulseLink 2s infinite; }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
                transform: scale(1);
                pointer-events: none;
                animation: pulse 2s infinite; }
            &-icon {
                position: relative;
                z-index: 1;
                width: 16px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                &--active {
                    position: absolute;
                    opacity: 0; }
                &--default, &--active {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: $tr; } } }
        &-1 {
            top: -17px;
            left: 0;
            right: 0;
            margin: auto;
            &.active {} }
        &-2 {
            right: -13px;
            top: 50px; }
        &-3 {
            bottom: -9px;
            right: 24px; }
        &-4 {
            bottom: -9px;
            left: 24px; }
        &-5 {
            top: 50px;
            left: -12px; }
        &.active {
            .tab-link {
                background: $white;
                animation: none;
                width: 36px;
                height: 36px;
                transform: translate(-2px, -2px);
                &:after, &:before {
                    opacity: 0; }
                &-icon {
                    &--default {
                        animation: none;
                        opacity: 0; }
                    &--active {
                        animation: none;
                        opacity: 1; } } } } }
    &-pentagon {
        background-image: url(../images/pentagon2.png);
        background-size: contain;
        width: 182px;
        height: 174px;
        display: flex;
        align-items: center;
        justify-content: center;
        canvas {
            margin-top: -12px; } }

    &-logo {
        margin-bottom: 22px;
        text-align: center;
        transform: translateY(-110px) scale(2);
        opacity: 0;
        transition: transform 1s 1s ease-out;
        img {
            width: 120px;
            display: inline-block;
            vertical-align: bottom; } }

    &-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 35px;
        &__logo {
            width: 40px;
            height: 48px;
            padding-top: 15px; }
        &__descr {
            margin-top: 12px;
            width: 100%;
            font-family: $np-nblack;
            font-size: 24px;
            line-height: 24px;
            text-transform: uppercase;
            color: $white; } }

    &-cnt {
        position: relative;
        margin-top: 32px;
        opacity: 0;
        transition: opacity 1s 1.5s ease-out;
        overflow: hidden; }
    &-pane {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        &.active {
            position: relative;
            .cognition-pane__inner {
                opacity: 1;
                left: 0;
                transition: left .8s ease-out; } }
        &.post-active {
            .cognition-pane__inner {
                left: 100%;
                padding-left: 16px;
                transition: left .8s ease-out; } }
        &__inner {
            z-index: 1;
            position: relative;
            opacity: 1;
            min-height: 124px;
            padding-left: 0;
            left: -100%; }


        &__descr {
            width: 100%; }

        &__title {
            font-family: $np-regular;
            font-size: 21px;
            line-height: 32px;
            text-transform: uppercase;
            color: $white;
            margin-bottom: 4px;
            letter-spacing: -0.5px;
            span {
                padding-left: 6px;
                font-family: $np-nblack;
                font-size: 24px;
                line-height: 24px;
                color: $red; } }

        &__txt {
            max-width: 182px;
            font-size: 14px;
            line-height: 21px;
            color: #F4F4F4; }
        &__chart {
            position: absolute;
            width: 160px;
            top: 0;
            right: 0; } }

    &-links {
        text-align: center; }

    &-link {
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: $white;
        position: relative;
        &:after {
            content: '';
            height: 1px;
            width: 100%;
            background: $white;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto; }
        &:hover, &:focus, &:active {
            color: $red;
            &:after {
                background: $red; } } } }
