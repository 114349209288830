@import "./normalize";
@import "./vars";
@import "./mixins";
@import "./fonts";
@import "./sprite_generated";

// From libs:
@import "../../libs/js-datepicker/dist/datepicker.min";
@import "../../libs/custom-select/build/custom-select";
@import "../../libs/swiper/swiper-bundle.min";

* {
    box-sizing: border-box;
 }    // min-height: 0.001vw; // — Fixes Safari bug with viewport units in calc()

html, body {
    overflow-x: hidden; }

body {
    display: flex;
    flex-direction: column;
    background: #000000;
    font-family: $np-regular, "Helvetica", Arial, sans-serif;
    width: 100%;
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    &.body--bg {
        background-image: url(../images/bg.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; } }

ul {
    padding: 0;
    margin: 0;
    list-style: none; }

h1, h2, h3, h4, h5, h6, p {
    margin: 0; }

h1 {
    font-family: $np-nblack;
    font-size: 42px;
    line-height: 52px;
    color: $white;
    text-align: center; }

h2 {
    font-family: $np-cblack;
    font-size: 48px;
    line-height: 48px;
    text-transform: uppercase;
    color: $white;
    &.form-title {
        font-size: 42px;
        line-height: 42px;
        &--sm {
            font-family: $np-cdblack;
            font-size: 28px;
            line-height: 40px; } }
    &.completed-subtitle {
        font-size: 42px;
        line-height: 42px; }
    &.stack-up-title {
        font-size: 72px;
        line-height: 72px;
        text-align: center; }
    span {
        color: $red; } }

h3 {
    font-family: $np-nblack;
    font-size: 21px;
    line-height: 32px;
    text-transform: uppercase;
    color: $white; }

a {
    text-decoration: none;
    transition: $tr; }

.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 850px;
    min-width: 320px;
    margin: 0 auto;
    padding: 0 16px; }


.btn {
    filter: drop-shadow(10px 10px 30px rgba(27, 15, 15, 0.05));
    border: 0;
    padding: 6px 15px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 328px;
    width: 100%;
    margin: auto;
    font-family: $np-cblack;
    font-size: 42px;
    line-height: 42px;
    text-transform: uppercase;
    color: $white;
    background: #DC0019;
    box-shadow: 10px 10px 30px rgba(27, 15, 15, 0.05);
    transition: $tr;
    span {
        padding-left: 4px; }
    &:hover, &:focus, &:active {
        color: $red;
        background: $white; } }

[class^=svg-image-] {
    display: inline-block;
    vertical-align: middle; }


