.smedia {
    flex-direction: column;
    width: 100%;
    max-width: 952px;
    padding: 0 16px;
    min-height: 1080px;
    margin: 0 auto;
    color: $white;

    img {
        width: 100%;
        height: auto; }

    &__header {
        padding-top: 32px; }

    &__title {
        font-family: $np-cblack;
        font-size: 96px;
        line-height: 96px;
        text-transform: uppercase;
        color: $red; }

    &__subtitle {
        margin-top: 24px;
        font-family: $np-nblack;
        font-size: 42px;
        line-height: 42px;
        text-transform: uppercase;
        text-align: center; }

    &__main {
        display: flex;
        justify-content: center;
        margin-top: 80px; }

    &__graphs {
        width: 48.9%;
        max-width: 450px;
        padding-right: 80px; }

    &__list {
        flex-grow: 1; }

    &__pentagon {}

    &__brand {
        width: 179px;
        margin: 32px auto; }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 51px;
        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: -30px;
            height: 2px;
            background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 100%); } }

    &__ico {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.2); }

    &__name {
        flex-grow: 1;
        padding-left: 32px;
        font-family: $np-nblack;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase; }

    &__val {
        font-family: $np-nblack;
        font-size: 32px;
        line-height: 40px;
        color: $red; }

    &__footer {}


    &__slogan {
        width: 100%;
        max-width: 600px;
        margin: 5px auto 0; }

    &__logo {
        margin-top: 25px;
        width: 100%;
        max-width: 106px;
        margin: 25px auto 0; } }
