.drill {
    padding-top: 24px;
    padding-bottom: 125px;

    &__header {
        display: flex;
        align-items: center; }

    &__icon {
        width: 32px;
        img {
            width: 100%;
            height: auto; } }

    &__title {
        flex-grow: 1;
        padding-left: 16px;
        margin-top: -12px;
        color: $red; }

    &__list {
        display: flex;
        align-items: center; }

    &__item {
        width: 24px;
        height: 24px;
        margin-top: -4px;
        margin-left: 8px;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            width: 14.4px; } }

    &__subtitle {
        margin-top: 12px;
        font-family: $np-black;
        font-size: 24px;
        line-height: 26px;
        color: $white; }

    &__how-to {
        margin-top: 24px;
        font-family: $np-semibold;
        font-size: 21px;
        line-height: 21px;
        text-transform: uppercase;
        color: $red; }

    &__body {
        margin-top: 12px;
        font-family: $np-black;
        font-size: 16px;
        line-height: 21px;
        color: $white; }

    &__img {
        text-align: center;
        margin-top: 16px; }
    &__example {
        text-align: center;
        margin-top: 16px;
        video {
            height: 100%; } }

    &-main {
        padding: 0;
        .wrapper {
            font-size: 60px;
            line-height: 21px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%; }
        a {
            color: $white; } }

    &-video {
        &__wrap {
            video {
                width: 100%;
                height: 100%;
                object-fit: cover; } }
        &__btns {
            display: flex;
            align-items: center;
            justify-content: center;
            bottom: 24px;
            position: fixed;
            left: 0;
            right: 0;
            margin: auto;
            width: 100%;
            a {
                font-weight: 600;
                font-size: 24px;
                line-height: 24px;
                text-transform: uppercase;
                color: $white; } } } }
