.popup {
    position: fixed;
    top: -10px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: -1;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s ease;


    &__wrap {
        position: fixed;
        top: 40px;
        bottom: 40px;
        left: 20px;
        right: 20px;
        background: $black;
        overflow: auto; }

    &__close {
        z-index: 10;
        position: fixed;
        top: 50px;
        right: 25px;
        padding: 10px;
        cursor: pointer; }

    &__inner {
        padding: 24px;
        p {
            margin: 24px 0;
            font-size: 14px;
            line-height: 21px;
            color: $white; } } }

.active-popup + .popup {
        top: 0;
        z-index: 10;
        opacity: 1;
        pointer-events: auto; }
