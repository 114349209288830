.chart {
    $ch: &;

    &-wrapp {
        position: relative;
        overflow: hidden;
        max-width: 140px;
        width: 100%;
        margin-left: auto; }
    &-full {
        #{$ch}-wrapp {
            max-width: 338px;
            padding: 5px 5px 0;
            margin: auto; } }

    &-inner {
        position: relative; }
    &-field {
        width: 100%;
        height: 84px;
        background-color: #fff;
        position: relative;
        opacity: 0.6;
        clip-path: polygon(100% 0, 0% 100%, 100% 100%); }
    &-full {
        #{$ch}-field {
            height: 127px; } }

    &-gradient {
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0.6;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%); }
    &-point, &-indicator {
        height: 1px;
        width: 100%;
        transform: rotate(-30.5deg);
        transform-origin: bottom left;
        bottom: 0;
        left: 0;
        position: absolute; }
    &-full {
        #{$ch}-point, #{$ch}-indicator {
            transform: rotate(-21deg); } }

    &-point {
        z-index: 3;
        &__el {
            width: 5px;
            height: 5px;
            position: absolute;
            background: $white;
            top: -2px;
            border-radius: 50%; } }
    &-indicator {
        &__el {
            width: 1px;
            border-left: 1px dashed #fff;
            height: 82px;
            top: 0;
            left: 0;
            position: absolute;
            transform: rotate(30.5deg) translateX(2px);
            transform-origin: top; } }
    &-full {
        #{$ch}-indicator {
            &__el {
                height: 125px;
                transform: rotate(21deg) translateX(2px); } } }

    &-info {
        margin-top: 6px;
        color: #F4F4F4;
        font-family: $np-nblack;
        font-size: 16px;
        line-height: 16px;
        text-align: right; }
    &-full {
        #{$ch}-info {
            position: absolute;
            z-index: 3;
            bottom: 0;
            right: 40px;
            margin-top: 0;
            font-family: $np-regular;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.25px;
            text-align: right; } }

    &-result {
        font-family: $np-black;
        font-size: 24px;
        line-height: 32px;
        text-transform: uppercase;
        padding-left: 44px;
        &-np {
            padding-left: 0; } }
    &--red {
        .chart {
            &-result {
                color: $red; }
            &-info {
                span {
                    color: $red; } }
            &-point {
                background: $red; }
            &-field {
                background: #DC0019; } } }
    &--yellow {
        .chart {
            &-result {
                color: #F1CE43; }
            &-info {
                span {
                    color: #F1CE43; } }
            &-point {
                background: #F1CE43; }
            &-field {
                background: #F1CE43 0%; } } } }

