%svg-common {
	background: url("../images/sprites/main.svg") no-repeat;
}

.svg-image-about-icon-1 {
	@extend %svg-common;
	background-position: 0 0;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-about-icon-1 {
	background-position: 0 0;
}
@mixin svg-image-about-icon-1-hover {
	.svg-image-about-icon-1 {
		@include svg-pos-image-about-icon-1-hover
	}
}

.svg-image-about-icon-1:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/18)*100%;
}

.svg-image-about-icon-1-dims {
	width: 18px;
	height: 26px;
}

.svg-image-about-icon-1-bl {
	@extend %svg-common;
	background-position: 48.113207547169814% 26.804123711340207%;
	background-size: (122/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-about-icon-1-bl {
	background-position: 48.113207547169814% 26.804123711340207%;
}
@mixin svg-image-about-icon-1-bl-hover {
	.svg-image-about-icon-1-bl {
		@include svg-pos-image-about-icon-1-bl-hover
	}
}

.svg-image-about-icon-1-bl:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/16)*100%;
}

.svg-image-about-icon-1-bl-dims {
	width: 16px;
	height: 22px;
}

.svg-image-about-icon-1-exsm {
	@extend %svg-common;
	background-position: 78.18181818181819% 37.62376237623762%;
	background-size: (122/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-about-icon-1-exsm {
	background-position: 78.18181818181819% 37.62376237623762%;
}
@mixin svg-image-about-icon-1-exsm-hover {
	.svg-image-about-icon-1-exsm {
		@include svg-pos-image-about-icon-1-exsm-hover
	}
}

.svg-image-about-icon-1-exsm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/12)*100%;
}

.svg-image-about-icon-1-exsm-dims {
	width: 12px;
	height: 18px;
}

.svg-image-about-icon-1-sm {
	@extend %svg-common;
	background-position: 66.0377358490566% 0;
	background-size: (122/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-about-icon-1-sm {
	background-position: 66.0377358490566% 0;
}
@mixin svg-image-about-icon-1-sm-hover {
	.svg-image-about-icon-1-sm {
		@include svg-pos-image-about-icon-1-sm-hover
	}
}

.svg-image-about-icon-1-sm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (22/16)*100%;
}

.svg-image-about-icon-1-sm-dims {
	width: 16px;
	height: 22px;
}

.svg-image-about-icon-2 {
	@extend %svg-common;
	background-position: 18.75% 0;
	background-size: (122/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-about-icon-2 {
	background-position: 18.75% 0;
}
@mixin svg-image-about-icon-2-hover {
	.svg-image-about-icon-2 {
		@include svg-pos-image-about-icon-2-hover
	}
}

.svg-image-about-icon-2:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-about-icon-2-dims {
	width: 26px;
	height: 26px;
}

.svg-image-about-icon-2-bl {
	@extend %svg-common;
	background-position: 82.6923076923077% 55.445544554455445%;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-about-icon-2-bl {
	background-position: 82.6923076923077% 55.445544554455445%;
}
@mixin svg-image-about-icon-2-bl-hover {
	.svg-image-about-icon-2-bl {
		@include svg-pos-image-about-icon-2-bl-hover
	}
}

.svg-image-about-icon-2-bl:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-about-icon-2-bl-dims {
	width: 18px;
	height: 18px;
}

.svg-image-about-icon-2-exsm {
	@extend %svg-common;
	background-position: 0 82.17821782178218%;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-about-icon-2-exsm {
	background-position: 0 82.17821782178218%;
}
@mixin svg-image-about-icon-2-exsm-hover {
	.svg-image-about-icon-2-exsm {
		@include svg-pos-image-about-icon-2-exsm-hover
	}
}

.svg-image-about-icon-2-exsm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-about-icon-2-exsm-dims {
	width: 18px;
	height: 18px;
}

.svg-image-about-icon-2-sm {
	@extend %svg-common;
	background-position: 17.307692307692307% 82.17821782178218%;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-about-icon-2-sm {
	background-position: 17.307692307692307% 82.17821782178218%;
}
@mixin svg-image-about-icon-2-sm-hover {
	.svg-image-about-icon-2-sm {
		@include svg-pos-image-about-icon-2-sm-hover
	}
}

.svg-image-about-icon-2-sm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-about-icon-2-sm-dims {
	width: 18px;
	height: 18px;
}

.svg-image-about-icon-3 {
	@extend %svg-common;
	background-position: 45.833333333333336% 0;
	background-size: (122/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-about-icon-3 {
	background-position: 45.833333333333336% 0;
}
@mixin svg-image-about-icon-3-hover {
	.svg-image-about-icon-3 {
		@include svg-pos-image-about-icon-3-hover
	}
}

.svg-image-about-icon-3:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (25/26)*100%;
}

.svg-image-about-icon-3-dims {
	width: 26px;
	height: 25px;
}

.svg-image-about-icon-3-bl {
	@extend %svg-common;
	background-position: 38.46153846153846% 64.70588235294117%;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-about-icon-3-bl {
	background-position: 38.46153846153846% 64.70588235294117%;
}
@mixin svg-image-about-icon-3-bl-hover {
	.svg-image-about-icon-3-bl {
		@include svg-pos-image-about-icon-3-bl-hover
	}
}

.svg-image-about-icon-3-bl:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (17/18)*100%;
}

.svg-image-about-icon-3-bl-dims {
	width: 18px;
	height: 17px;
}

.svg-image-about-icon-3-exsm {
	@extend %svg-common;
	background-position: 55.76923076923077% 64.07766990291262%;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-about-icon-3-exsm {
	background-position: 55.76923076923077% 64.07766990291262%;
}
@mixin svg-image-about-icon-3-exsm-hover {
	.svg-image-about-icon-3-exsm {
		@include svg-pos-image-about-icon-3-exsm-hover
	}
}

.svg-image-about-icon-3-exsm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (16/18)*100%;
}

.svg-image-about-icon-3-exsm-dims {
	width: 18px;
	height: 16px;
}

.svg-image-about-icon-3-sm {
	@extend %svg-common;
	background-position: 34.61538461538461% 81.37254901960785%;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-about-icon-3-sm {
	background-position: 34.61538461538461% 81.37254901960785%;
}
@mixin svg-image-about-icon-3-sm-hover {
	.svg-image-about-icon-3-sm {
		@include svg-pos-image-about-icon-3-sm-hover
	}
}

.svg-image-about-icon-3-sm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (17/18)*100%;
}

.svg-image-about-icon-3-sm-dims {
	width: 18px;
	height: 17px;
}

.svg-image-about-icon-4 {
	@extend %svg-common;
	background-position: 0 27.956989247311828%;
	background-size: (122/25)*100% auto;
	width: 25px;

}
@mixin svg-pos-image-about-icon-4 {
	background-position: 0 27.956989247311828%;
}
@mixin svg-image-about-icon-4-hover {
	.svg-image-about-icon-4 {
		@include svg-pos-image-about-icon-4-hover
	}
}

.svg-image-about-icon-4:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/25)*100%;
}

.svg-image-about-icon-4-dims {
	width: 25px;
	height: 26px;
}

.svg-image-about-icon-4-bl {
	@extend %svg-common;
	background-position: 100% 0;
	background-size: (122/17)*100% auto;
	width: 17px;

}
@mixin svg-pos-image-about-icon-4-bl {
	background-position: 100% 0;
}
@mixin svg-image-about-icon-4-bl-hover {
	.svg-image-about-icon-4-bl {
		@include svg-pos-image-about-icon-4-bl-hover
	}
}

.svg-image-about-icon-4-bl:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/17)*100%;
}

.svg-image-about-icon-4-bl-dims {
	width: 17px;
	height: 18px;
}

.svg-image-about-icon-4-exsm {
	@extend %svg-common;
	background-position: 99.05660377358491% 17.821782178217823%;
	background-size: (122/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-about-icon-4-exsm {
	background-position: 99.05660377358491% 17.821782178217823%;
}
@mixin svg-image-about-icon-4-exsm-hover {
	.svg-image-about-icon-4-exsm {
		@include svg-pos-image-about-icon-4-exsm-hover
	}
}

.svg-image-about-icon-4-exsm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/16)*100%;
}

.svg-image-about-icon-4-exsm-dims {
	width: 16px;
	height: 18px;
}

.svg-image-about-icon-4-sm {
	@extend %svg-common;
	background-position: 100% 35.64356435643565%;
	background-size: (122/17)*100% auto;
	width: 17px;

}
@mixin svg-pos-image-about-icon-4-sm {
	background-position: 100% 35.64356435643565%;
}
@mixin svg-image-about-icon-4-sm-hover {
	.svg-image-about-icon-4-sm {
		@include svg-pos-image-about-icon-4-sm-hover
	}
}

.svg-image-about-icon-4-sm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/17)*100%;
}

.svg-image-about-icon-4-sm-dims {
	width: 17px;
	height: 18px;
}

.svg-image-about-icon-5 {
	@extend %svg-common;
	background-position: 26.041666666666668% 27.956989247311828%;
	background-size: (122/26)*100% auto;
	width: 26px;

}
@mixin svg-pos-image-about-icon-5 {
	background-position: 26.041666666666668% 27.956989247311828%;
}
@mixin svg-image-about-icon-5-hover {
	.svg-image-about-icon-5 {
		@include svg-pos-image-about-icon-5-hover
	}
}

.svg-image-about-icon-5:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (26/26)*100%;
}

.svg-image-about-icon-5-dims {
	width: 26px;
	height: 26px;
}

.svg-image-about-icon-5-bl {
	@extend %svg-common;
	background-position: 0 100%;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-about-icon-5-bl {
	background-position: 0 100%;
}
@mixin svg-image-about-icon-5-bl-hover {
	.svg-image-about-icon-5-bl {
		@include svg-pos-image-about-icon-5-bl-hover
	}
}

.svg-image-about-icon-5-bl:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-about-icon-5-bl-dims {
	width: 18px;
	height: 18px;
}

.svg-image-about-icon-5-exsm {
	@extend %svg-common;
	background-position: 17.307692307692307% 100%;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-about-icon-5-exsm {
	background-position: 17.307692307692307% 100%;
}
@mixin svg-image-about-icon-5-exsm-hover {
	.svg-image-about-icon-5-exsm {
		@include svg-pos-image-about-icon-5-exsm-hover
	}
}

.svg-image-about-icon-5-exsm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-about-icon-5-exsm-dims {
	width: 18px;
	height: 18px;
}

.svg-image-about-icon-5-sm {
	@extend %svg-common;
	background-position: 34.61538461538461% 100%;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-about-icon-5-sm {
	background-position: 34.61538461538461% 100%;
}
@mixin svg-image-about-icon-5-sm-hover {
	.svg-image-about-icon-5-sm {
		@include svg-pos-image-about-icon-5-sm-hover
	}
}

.svg-image-about-icon-5-sm:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-about-icon-5-sm-dims {
	width: 18px;
	height: 18px;
}

.svg-image-about-icon-6 {
	@extend %svg-common;
	background-position: 95.45454545454545% 53.46534653465346%;
	background-size: (122/12)*100% auto;
	width: 12px;

}
@mixin svg-pos-image-about-icon-6 {
	background-position: 95.45454545454545% 53.46534653465346%;
}
@mixin svg-image-about-icon-6-hover {
	.svg-image-about-icon-6 {
		@include svg-pos-image-about-icon-6-hover
	}
}

.svg-image-about-icon-6:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/12)*100%;
}

.svg-image-about-icon-6-dims {
	width: 12px;
	height: 18px;
}

.svg-image-arrow-top {
	@extend %svg-common;
	background-position: 94.5945945945946% 83.33333333333333%;
	background-size: (122/11)*100% auto;
	width: 11px;

}
@mixin svg-pos-image-arrow-top {
	background-position: 94.5945945945946% 83.33333333333333%;
}
@mixin svg-image-arrow-top-hover {
	.svg-image-arrow-top {
		@include svg-pos-image-arrow-top-hover
	}
}

.svg-image-arrow-top:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (11/11)*100%;
}

.svg-image-arrow-top-dims {
	width: 11px;
	height: 11px;
}

.svg-image-back {
	@extend %svg-common;
	background-position: 0 49.523809523809526%;
	background-size: (122/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-back {
	background-position: 0 49.523809523809526%;
}
@mixin svg-image-back-hover {
	.svg-image-back {
		@include svg-pos-image-back-hover
	}
}

.svg-image-back:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/20)*100%;
}

.svg-image-back-dims {
	width: 20px;
	height: 14px;
}

.svg-image-goto {
	@extend %svg-common;
	background-position: 64.81481481481481% 20.952380952380953%;
	background-size: (122/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-goto {
	background-position: 64.81481481481481% 20.952380952380953%;
}
@mixin svg-image-goto-hover {
	.svg-image-goto {
		@include svg-pos-image-goto-hover
	}
}

.svg-image-goto:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/14)*100%;
}

.svg-image-goto-dims {
	width: 14px;
	height: 14px;
}

.svg-image-goto-red {
	@extend %svg-common;
	background-position: 64.81481481481481% 34.285714285714285%;
	background-size: (122/14)*100% auto;
	width: 14px;

}
@mixin svg-pos-image-goto-red {
	background-position: 64.81481481481481% 34.285714285714285%;
}
@mixin svg-image-goto-red-hover {
	.svg-image-goto-red {
		@include svg-pos-image-goto-red-hover
	}
}

.svg-image-goto-red:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (14/14)*100%;
}

.svg-image-goto-red-dims {
	width: 14px;
	height: 14px;
}

.svg-image-nav-arrow {
	@extend %svg-common;
	background-position: 83.49514563106796% 0;
	background-size: (122/19)*100% auto;
	width: 19px;

}
@mixin svg-pos-image-nav-arrow {
	background-position: 83.49514563106796% 0;
}
@mixin svg-image-nav-arrow-hover {
	.svg-image-nav-arrow {
		@include svg-pos-image-nav-arrow-hover
	}
}

.svg-image-nav-arrow:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (19/19)*100%;
}

.svg-image-nav-arrow-dims {
	width: 19px;
	height: 19px;
}

.svg-image-nav-arrow-red {
	@extend %svg-common;
	background-position: 82.6923076923077% 19%;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-nav-arrow-red {
	background-position: 82.6923076923077% 19%;
}
@mixin svg-image-nav-arrow-red-hover {
	.svg-image-nav-arrow-red {
		@include svg-pos-image-nav-arrow-red-hover
	}
}

.svg-image-nav-arrow-red:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (19/18)*100%;
}

.svg-image-nav-arrow-red-dims {
	width: 18px;
	height: 19px;
}

.svg-image-pointinghand {
	@extend %svg-common;
	background-position: 99.05660377358491% 71.2871287128713%;
	background-size: (122/16)*100% auto;
	width: 16px;

}
@mixin svg-pos-image-pointinghand {
	background-position: 99.05660377358491% 71.2871287128713%;
}
@mixin svg-image-pointinghand-hover {
	.svg-image-pointinghand {
		@include svg-pos-image-pointinghand-hover
	}
}

.svg-image-pointinghand:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/16)*100%;
}

.svg-image-pointinghand-dims {
	width: 16px;
	height: 18px;
}

.svg-image-share {
	@extend %svg-common;
	background-position: 0 64.70588235294117%;
	background-size: (122/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-share {
	background-position: 0 64.70588235294117%;
}
@mixin svg-image-share-hover {
	.svg-image-share {
		@include svg-pos-image-share-hover
	}
}

.svg-image-share:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (17/20)*100%;
}

.svg-image-share-dims {
	width: 20px;
	height: 17px;
}

.svg-image-share-red {
	@extend %svg-common;
	background-position: 19.607843137254903% 64.70588235294117%;
	background-size: (122/20)*100% auto;
	width: 20px;

}
@mixin svg-pos-image-share-red {
	background-position: 19.607843137254903% 64.70588235294117%;
}
@mixin svg-image-share-red-hover {
	.svg-image-share-red {
		@include svg-pos-image-share-red-hover
	}
}

.svg-image-share-red:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (17/20)*100%;
}

.svg-image-share-red-dims {
	width: 20px;
	height: 17px;
}

.svg-image-times {
	@extend %svg-common;
	background-position: 51.92307692307692% 100%;
	background-size: (122/18)*100% auto;
	width: 18px;

}
@mixin svg-pos-image-times {
	background-position: 51.92307692307692% 100%;
}
@mixin svg-image-times-hover {
	.svg-image-times {
		@include svg-pos-image-times-hover
	}
}

.svg-image-times:before {
	content:"";
	display:block;
	font-size:0;
	line-height:0;
	padding-top: (18/18)*100%;
}

.svg-image-times-dims {
	width: 18px;
	height: 18px;
}


.image-by-height { width:auto; }
.image-by-height:before { content:none; }
.image-by-height > svg { height: inherit; }
.image-by-height > img { height: inherit !important; vertical-align: top !important; margin: 0 !important; padding: 0 !important; max-width: none !important; max-height: none !important; border: 0 !important; opacity: 0 !important; }